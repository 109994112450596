<template>
    <div class="clients-to-visit_win">
        <div class="clients-summry">
            <div class="close-win" @click="close">
            <i class="material-icons">disabled_by_default</i>
            </div>
            <div class="tool-bar">
                <el-select v-model="selected_agent" placeholder="סנן לפי סוכן" clearable>
                    <el-option v-for="agent in agents" :key="agent" :label="agent" :value="agent"></el-option>
                </el-select>
                <el-button type="primary" class="tool-btn" @click="filter='מחוץ למסלול'">ביקורים מחוץ למסלול</el-button>
                <el-button type="primary" class="tool-btn" @click="filter='טרם ביקר'">טרם בוקרו</el-button>
                <el-button type="warning" class="tool-btn" @click="filter='', selected_agent = null">נקה סינונים</el-button>
            </div>
            <div class="results-teable">
                <table id="table" v-if="clients_computed">
                    <tr>
                        <th>סוכן</th>
                        <th>לקוח</th>
                        <th>שעת ביקור</th>
                        <th>שם</th>
                        <th>הערה 1</th>
                        <th>הערה 2</th>
                        <th>הזמנה</th>
                        <th>סכום הזמנה</th>
                    </tr>
                    <template v-for="(client,i) in clients_computed" :key="i">
                        <tr :style="check_sale(client)">
                            <td>{{client.agent}}</td>
                            <td>{{client.client}}</td>
                            <td v-if="client.visit_date">{{new Date(client.visit_date).toLocaleTimeString('he')}}</td>
                            <td v-else> טרם ביקר</td>
                            <td>{{client.name}}</td>
                            <td>{{client.subject}}</td>
                            <td>{{client.subject_b}}</td>
                            <td>{{client.order}}</td>
                            <td>{{client.order_amount}}</td>
                        </tr>
                    </template>
                </table>
            </div>
            <div class="color-map">
                <p style="color:rgba(7, 221, 7, 0.962); font-weight:500; display:flex; align-items:center;">
                    <i class="material-icons">adjust</i>
                    בוצעה הזמנה
                </p>
                <p style="color:var(--warning); font-weight:500; display:flex; align-items:center;">
                    <i class="material-icons">adjust</i>
                    ביקור ללא זמנה 
                </p>
                <p style="color:var(--danger); font-weight:500; display:flex; align-items:center;">
                    <i class="material-icons">adjust</i>
                    טרם בוקר 
                </p>
            </div>
        </div>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';
export default {
props:["clients", "ext_filter"],
emits:["close"],
setup(props, {emit}){

    const close =() => {
        emit("close");
    }

    const filter = ref('');
    if(props.ext_filter){
        filter.value = props.ext_filter
    }
    const clients_computed = ref(computed(() => {
        if(props.clients.orders.length > 0){
            let _clients = props.clients.orders.concat(props.clients.clients_to_attend);
            switch (filter.value) {
                case 'מחוץ למסלול':
                    _clients = _clients.filter(sale => {
                        return sale.subject && sale.subject.includes('מחוץ למסלול')
                    })
                    break;
                case 'טרם ביקר':
                    _clients = _clients.filter(sale => {
                        return !sale.visit_date
                    })
                    break;
            
                default:
                    break;
            }

            if(!selected_agent.value){
                return _clients
            }else{
                return _clients.filter(sale => {
                    return sale.agent == selected_agent.value
                })
            }
        }
        else return null
    }))

    const agents = ref(computed(() => {
        let ids = [];
        if(clients_computed.value){
            clients_computed.value.forEach(sale => {
                if(!ids.includes(sale.agent)){
                    ids.push(sale.agent)
                }
            })
        }
        ids = ids.sort((aa, bb) => {
            return aa - bb
        })
        return ids
    }))

    const selected_agent = ref(null);

    const check_sale = (sale) => {
        if(sale.order_amount > 0){
            return 'background: rgba(7, 221, 7, 0.662);'
        }else{
            if(sale.order_amount < 0){
                return 'background: rgba(241, 97, 169, 0.562);'
            }else{
                if(sale.subject && sale.subject.includes('אי מכירה')){
                    return 'background: var(--warning);'
                }
                if(sale.subject && sale.subject.includes('אין התייחסות')){
                    return 'background: var(--danger);'
                }
            }
        }
    }
    
    return{
        close, clients_computed, agents, selected_agent, check_sale, filter
    }
}
}
</script>

<style scoped>
.clients-to-visit_win{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.603);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.clients-summry{
    position: relative;
    width: 100%;
    max-width: 760px;
    height: 90%;
    background: var(--main);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.253);
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.close-win{
    position: absolute;
    top: -25px;
    left: 5px;
    cursor: pointer;
    color: white;
}
.tool-bar{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.results-teable{
    width: 100%;
    height: calc(100% - 50px - 50px);
    overflow-y: auto;
}
#table {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
    background: white;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
}
#table th{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
.color-map{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.tool-btn{
    margin: 0;
    margin-right: 5px;
}
</style>